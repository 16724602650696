import toggleHeader from './components/toggleHeader'
import toggleMenu from './components/toggleMenu'
import initializeFlickity from './components/initializeFlickity'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    toggleHeader()
    toggleMenu()
    initializeFlickity()
  },
  false
)
