/**
 * TOGGLE HEADER ONSCROLL
 */

 const toggleHeader = () => {
  const header = document.querySelector("header");
  const burger = document.querySelector('.hamburger');
  const is_page = document.querySelector('.page-banner');

  if(is_page) {
    burger.style.opacity = "1";
  }

  function toggleHeader(ev) {
    if (window.pageYOffset > 150) {
      header.classList.add("header--scrolled");
      burger.style.opacity = "1";
    } else {
      header.classList.remove("header--scrolled");
      if(!is_page) {
        burger.style.opacity = "0";
      }      
    }
  }
  window.addEventListener('scroll', function() {
    toggleHeader();
  });
}

export default toggleHeader