/**
 * INITIALIZE FLICKITY
 */

 const initializeFlickity = () => {
  var top_voice_slider1 = document.querySelector('.voice-slider1');
  if(top_voice_slider1) {
    new Flickity(top_voice_slider1, {
      contain: true,
      wrapAround: true,
      prevNextButtons: false
    });
  }

  var top_voice_slider2 = document.querySelector('.voice-slider2');
  if(top_voice_slider2) {
    new Flickity(top_voice_slider2, {
      contain: true,
      wrapAround: true,
      prevNextButtons: false
    });
  }
}

export default initializeFlickity
