/**
 * TOGGLE MENU (SP)
 * - show/hide menu
 * - toggle hamburger style
 * - close menu on anchor scroll
 */

 const toggleMenu = () => {
  const burger = document.querySelector('.hamburger');
  const menu = document.querySelector('.nav');
  const anchor = document.querySelectorAll(".nav__item");

  burger.addEventListener('click', () => {
    burger.classList.toggle('menu-open');
    menu.classList.toggle('menu-open');
  })
  
  for(let item of anchor) {
    item.addEventListener('click', () => {
      burger.classList.remove('menu-open');
      menu.classList.remove('menu-open');
    })
  }
}

export default toggleMenu